.logo {
  height: 45px;
  margin: 12px;
  background-image: url("/public/logo2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.site-layout-background {
  background: #fff;
}

.site-layout .site-layout-background {
  padding: 24px;
  min-height: 280px;
}
